// stylelint-disable declaration-no-important, selector-list-comma-newline-after

//
// Headings
//
.h1 {
  @extend h1;
}

.h2 {
  @extend h2;
}

.h3 {
  @extend h3;
}

.h4 {
  @extend h4;
}

.h5 {
  @extend h5;
}

.h6 {
  @extend h6;
}


.lead {
  @include font-size($lead-font-size);
  font-weight: $lead-font-weight;
}

// Type display classes
.display-1 {
  @include font-size($display1-size);
  font-weight: $display1-weight;
  line-height: $display-line-height;
}
.display-2 {
  @include font-size($display2-size);
  font-weight: $display2-weight;
  line-height: $display-line-height;
}
.display-3 {
  @include font-size($display3-size);
  font-weight: $display3-weight;
  line-height: $display-line-height;
}
.display-4 {
  @include font-size($display4-size);
  font-weight: $display4-weight;
  line-height: $display-line-height;
}

//
// Emphasis
//
.small {
  @extend small;
}

.mark {
  @extend mark;
}

//
// Lists
//

.list-unstyled {
  @include list-unstyled;
}

// Inline turns list items into inline-block
.list-inline {
  @include list-unstyled;
}
.list-inline-item {
  display: inline-block;

  &:not(:last-child) {
    margin-right: $list-inline-padding;
  }
}


//
// Misc
//

// Builds on `abbr`
.initialism {
  @include font-size(90%);
  text-transform: uppercase;
}

// Blockquotes
.blockquote {
  margin-bottom: $spacer;
  @include font-size($blockquote-font-size);
}

.blockquote-footer {
  display: block;
  @include font-size($blockquote-small-font-size);
  color: $blockquote-small-color;

  &::before {
    content: "\2014\00A0"; // em dash, nbsp
  }
}
